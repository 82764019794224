import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, Pagination, Section, SocialDesign } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import FontsDesktop from "@svg/work/marcos/fonts-desktop.svg"
import FontsTablet from "@svg/work/marcos/fonts-tablet.svg"
import FontsMobile from "@svg/work/marcos/fonts-mobile.svg"
import LogoSingle from "@svg/work/marcos/logo-single.svg"
import workData from "@json/work.json"

const MarcosPage = ({ data }) => {
  const {
    logoOne,
    logoTwo,
    logoThree,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
    printFourDesktopImage,
    printFourTabletImage,
    socialCardOneImage,
    socialCardTwoImage,
    socialCardThreeImage,
    printAdditionalOneImage,
    printAdditionalTwoImage,
    galleryOneImage,
    galleryTwoImage,
  } = data
  const { name, headline, theme } = workData["marcos"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-white" fillColor="text-white" />

      <main className="overflow-hidden">
        <HeroImage client="marcos" />
        <Category>Employer branding, Employee engagement</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Building an employer brand to satisfy a big appetite for growth.</Intro.Title>
            <div className="mt-10">
              <p>
                Even with over 1,100 locations and the distinction of being voted America’s Favorite Pizza Brand in a
                Harris Poll, Marco’s Pizza is still hungry for more. Their goal is as bold as their sauce: to find and
                hold their footing as one of America’s largest pizza companies. And in 2023 Marco’s was named among
                Entrepreneur's 2023 fastest-growing brands—ranking #51 on the publication’s Franchise 500 Hall of Fame
                list.
              </p>
              <p>
                That continued growth requires engaging, recruiting, and retaining the key talent needed to drive their
                business forward, so Marco’s asked JK to help them cook up an employer brand that’s as authentic and
                irresistible as a classic Pepperoni Magnifico.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables>
            <li>Employer branding</li>
            <li>EVP development</li>
            <li>Visual identity</li>
            <li>Messaging</li>
            <li>Copywriting</li>
            <li>Employee engagement</li>
            <li>Strategy &amp; communications planning</li>
            <li>Creative asset design &amp; development</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-8 xl:col-span-7">
            <Section.Title>Whipping up a “primo” identity from scratch.</Section.Title>
            <Section.Description>
              <p>
                JK worked closely with Marco’s stakeholders to build the foundations of a compelling and original
                employer brand, one rooted in their brand DNA of working hard to deliver amazing—or, as they say at
                Marco’s, “primo”—guest experiences.
              </p>
              <p>
                And so “Passion for Primo” was born, equal parts a rallying cry to engage and excite current employees
                and a magnet for prospective talent who want to work for a company that’s passionate about doing things
                the right way.
              </p>
              <p>
                Key components of the Passion for Primo platform were designed to make employees feel proud to work at
                Marco’s, because according to their employee engagement data, that was the leading driver of employee
                retention across the franchise system.
              </p>
            </Section.Description>
          </Section>
          <div className="mt-clamp-17-22">
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(logoOne)} alt="Logo 1" />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(logoTwo)} alt="Logo 2" />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage image={getImage(logoThree)} alt="Logo 3" />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-34-42">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Brand typography
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-6">
                <div className="display-desktop">
                  <FontsDesktop />
                </div>
                <div className="display-tablet">
                  <FontsTablet />
                </div>
                <div className="display-mobile">
                  <FontsMobile />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-40-77">
          <Section className="col-span-10">
            <Section.Title>The secret sauce, just for employees.</Section.Title>
            <Section.Description>
              <p>
                We brought the brand to life with a unique voice and visual identity that fit within the Marco’s
                corporate brand guidelines but still gave their talent brand its own unique style. This
                brand-within-a-brand was designed to be instantly identifiable by employees so they immediately knew
                when something was created just for them.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeTabletImage)}
                          alt="Print 3"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeDesktopImage)}
                          alt="Print 3"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFourTabletImage)}
                          alt="Print 4"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFourDesktopImage)}
                          alt="Print 4"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-54-74">
          <Section className="col-span-10 xl:col-span-9">
            <Section.Description>
              <p>
                We also crafted tailored messaging for three important audiences—store workers, store managers, and
                corporate employees—to instantly engage Marco’s people and prospects with the unique value the company
                delivers to its people.
              </p>
            </Section.Description>
          </Section>
          <div className={`${themeColor} relative mt-16 sm:mt-clamp-36-20`}>
            <div className="absolute inset-0">
              <div className="container">
                <div className="aspect-w-667 aspect-h-339 lg:aspect-w-1436 lg:aspect-h-292 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
            </div>
            <SocialDesign>
              <SocialDesign.Card className="h-full">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardOneImage)}
                  alt="Social Card 1"
                />
              </SocialDesign.Card>
              <SocialDesign.Card className="sm:ml-auto lg:pt-24">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardTwoImage)}
                  alt="Social Card 1"
                />
              </SocialDesign.Card>
              <SocialDesign.Card className="h-full lg:pt-52">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden shadow rounded-xl"
                  image={getImage(socialCardThreeImage)}
                  alt="Social Card 2"
                />
              </SocialDesign.Card>
            </SocialDesign>

            <div className="container pt-clamp-30-14 pb-clamp-23-41">
              <div className="grid-cols-12 grid-rows-5 lg:grid">
                <div className="col-start-1 col-end-9 row-start-1 row-end-4">
                  <GatsbyImage
                    className="overflow-hidden shadow rounded-2xl"
                    objectFit="contain"
                    image={getImage(printAdditionalOneImage)}
                    alt="Additional Print - 1"
                  />
                </div>
                <div className="relative flex col-start-5 col-end-13 row-start-3 row-end-6 mt-clamp-18-10 lg:mt-0">
                  <div className="mb-auto">
                    <GatsbyImage
                      className="overflow-hidden shadow rounded-2xl"
                      objectFit="contain"
                      image={getImage(printAdditionalTwoImage)}
                      alt="Additional Print - 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="relative pt-clamp-45-92 pb-clamp-23-38">
          <div className="absolute inset-0 flex">
            <div className="container mt-auto">
              <div className="aspect-w-326 aspect-h-790 sm:aspect-w-667 sm:aspect-h-987 lg:aspect-w-1436 lg:aspect-h-537 bg-work-marcos-green extend before:bg-work-marcos-green after:bg-work-marcos-green"></div>
            </div>
          </div>
          <div className="container">
            <div className="grid-work-marcos-gallery">
              <div className="flex justify-center marcos-gallery-copy">
                <div className="w-full sm:w-10/12 lg:ml-auto lg:w-5/6 pb-clamp-21-26">
                  <Section.Title>Rolling out something special.</Section.Title>
                  <Section.Description>
                    <p>
                      Once the employer brand was built, JK partnered with Marco’s to roll it out across their franchise
                      system, developing an engagement strategy and toolkits to help launch the brand in each store.
                    </p>
                    <p>
                      We then took a hands-on approach, attending the initial wave of store roll out sessions around the
                      country to gauge how the brand was being embraced by employees and ensuring the launch toolkits we
                      created made the process simple for store managers. After rave reviews by managers and lots of
                      enthusiastic employee feedback, we knew Passion for Primo was indeed ready for prime time.
                    </p>
                  </Section.Description>
                </div>
              </div>
              <div className="flex marcos-gallery-image-1 lg:pr-4">
                <div className="w-full sm:w-4/6 lg:w-full xl:ml-auto xl:w-5/6">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="w-full h-full"
                      image={getImage(galleryOneImage)}
                      alt="Gallery 1"
                    />
                  </div>
                </div>
              </div>
              <div className="flex marcos-gallery-image-2 mt-clamp-16-10 lg:mt-0">
                <div className="w-full sm:ml-auto sm:w-5/6 lg:ml-0 lg:w-full xl:w-5/6">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="w-full h-full"
                      image={getImage(galleryTwoImage)}
                      alt="Gallery 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-48-90 pb-clamp-48-56">
          <div className="container">
            <div className="flex flex-col-reverse grid-cols-12 lg:grid gap-x-4">
              <div className="flex col-span-5 lg:justify-center mt-clamp-17-22 lg:mt-0">
                <div className="w-1/2 sm:w-2/6 lg:w-3/5">
                  <LogoSingle />
                </div>
              </div>
              <div className="flex justify-center col-span-7">
                <div className="w-full sm:w-10/12 lg:w-full">
                  <Section.Title>One happy customer.</Section.Title>
                  <Section.Description>
                    <p>
                      Marco’s likes to say happy employees create happy guests, and while that’s true, we also learned
                      that happy employees create a happy Marco’s. Our friends there we’re so pleased with our
                      collaborative process and our commitment to helping them succeed that we’ve continued our
                      relationship to this day.
                    </p>
                    <p>
                      Our latest engagement is branding their corporate social responsibility outreach program, an
                      ambitious effort designed to strengthen the communities Marco’s calls home and give their
                      employees something else to make them proud to work for a company with a passion for doing the
                      right thing and doing it the right way.
                    </p>
                  </Section.Description>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-48-74 pb-clamp-27-43">
          <div className="container flex justify-center">
            <div data-aos="fade-up" className="w-full xl:w-10/12">
              <Carousel.Quote client="marcos" single={true}></Carousel.Quote>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <Pagination client="marcos" />
      </main>
    </Layout>
  )
}

export default MarcosPage

export const marcosQuery = graphql`
  query MarcosPageQuery {
    logoOne: file(relativePath: { eq: "work/marcos/logo-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoTwo: file(relativePath: { eq: "work/marcos/logo-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoThree: file(relativePath: { eq: "work/marcos/logo-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/marcos/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/marcos/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/marcos/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/marcos/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/marcos/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/marcos/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFourDesktopImage: file(relativePath: { eq: "work/marcos/print-04-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFourTabletImage: file(relativePath: { eq: "work/marcos/print-04-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardOneImage: file(relativePath: { eq: "work/marcos/social-card-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardTwoImage: file(relativePath: { eq: "work/marcos/social-card-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialCardThreeImage: file(relativePath: { eq: "work/marcos/social-card-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalOneImage: file(relativePath: { eq: "work/marcos/print-additional-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalTwoImage: file(relativePath: { eq: "work/marcos/print-additional-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryOneImage: file(relativePath: { eq: "work/marcos/gallery-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/marcos/gallery-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryOneImage: file(relativePath: { eq: "work/marcos/gallery-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    galleryTwoImage: file(relativePath: { eq: "work/marcos/gallery-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
